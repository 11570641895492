import React, { useState } from 'react';
import CreatePost from '../../../../components/create-post/create-post.component';
import { IPostModel } from '../../../../models';
import { GoPeople } from 'react-icons/go';
import { FaRegEye } from 'react-icons/fa';
import { MdOutlinePublic } from 'react-icons/md';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Post } from '../../../../components';
import { usePostGroupHook } from '../../../../hooks/group/usePostGroup';
import { size } from 'lodash';
import Loading from '../../../../components/loading/loading.component';
import { useParams } from 'react-router-dom';

interface TimelineTabProps {
  getHashTags: () => Promise<void>;
  detail: any;
}

const TimelineTab: React.FC<TimelineTabProps> = ({ getHashTags, detail }) => {
  const { groupId } = useParams<{ groupId: string }>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const {
    data: listPost,
    hasMore,
    loading,
    appendNewPost,
    removePost,
  } = usePostGroupHook({
    pageNumber,
    groupId: groupId || '',
  });
  console.log('🚀 ~ listPost:', listPost);

  return (
    <div className="flex 2xl:gap-12 gap-10 mt-8 max-lg:flex-col">
      <div className="flex-1 xl:space-y-6 space-y-3">
        {' '}
        <CreatePost
          onCreatedSuccess={async (newPost: IPostModel) => {
            appendNewPost(newPost);
            await getHashTags();
          }}
          isGroup
        />
        {size(listPost) ? (
          <InfiniteScroll
            dataLength={listPost.length}
            next={() => {
              setPageNumber((prev) => prev + 1);
            }}
            hasMore={hasMore}
            loader={<Loading classname="mx-auto" />}
            endMessage={null}
            scrollableTarget="body"
          >
            <div className="flex-1 xl:space-y-6 space-y-3">
              {listPost?.map((post, index) => {
                return <Post removePost={removePost} key={post.id} post={post} />;
              })}
            </div>
          </InfiniteScroll>
        ) : null}
        {!size(listPost) ? (
          <p className="text-sm text-slate-800 userDonates:text-slate-50 font-medium text-center mt-10">
            Bạn chưa có bài viết nào
          </p>
        ) : null}
      </div>
      <div className="lg:w-[400px]">
        <div className="lg:space-y-4 lg:pb-8 max-lg:grid sm:grid-cols-2 max-lg:gap-6 uk-sticky uk-active uk-sticky-fixed">
          {' '}
          <div className="box p-5 px-6">
            <div className="flex items-ce justify-between text-black dark:text-white">
              <h3 className="font-bold text-lg"> Giới thiệu </h3>
            </div>

            <ul className="text-gray-700 space-y-4 mt-4 text-sm dark:text-white/80">
              <li>{detail?.groupDescription}</li>
              <li className="flex items-start gap-3">
                <MdOutlinePublic size={24} />
                <div>
                  <span className="font-semibold text-black dark:text-white uppercase">{detail?.privacySettings} </span>
                  <p> Bất kỳ ai cũng có thể thấy ai trong nhóm và những gì họ đăng.</p>
                </div>
              </li>
              <li className="flex items-start gap-3">
                <FaRegEye size={20} />
                <div>
                  <span className="font-semibold text-black dark:text-white uppercase">
                    {detail?.privacySettings === 'public' ? 'Visible' : 'Hidden'}
                  </span>
                  <p>Bất kỳ ai cũng có thể tìm thấy nhóm này</p>
                </div>
              </li>
              <li className="flex items-center gap-3">
                <GoPeople size={20} />
                <div>
                  Thành viên <span className="font-semibold text-black dark:text-white"> {detail?.memberCount}</span>{' '}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimelineTab;

import React, { useState } from 'react';
import DialogComponent from '../dialog/dialog.component';
import { Delete } from 'lucide-react';
import { postService } from '../../services/post.service';
import toast from 'react-hot-toast';

interface ConfirmReactPostProps {
  postId?: string;
  removePost?: any;
}

const DeletePost = ({ postId, removePost }: ConfirmReactPostProps) => {
  const [visible, setVisible] = useState(false);

  const onClose = () => {
    setVisible(false);
  };
  const onConfirm = async () => {
    await postService.deletePost(postId);
    toast.success('Đã xóa bài viết');
    setVisible(false);
    removePost(postId);
  };

  return (
    <div>
      <button
        onClick={() => setVisible(true)}
        className="rounded-md flex items-center space-x-2 w-full p-2 hover:bg-gray-1 dark:hover:bg-gray-10 "
      >
        <Delete size={14} />
        <span className="text-xs">Xóa bài viết</span>
      </button>
      <DialogComponent visible={visible} onClose={onClose} placement="CENTER">
        <div className="mt-2">
          <p className="text-slate-800 dark:text-slate-50 text-base font-medium">Bạn muốn xóa bài viết này?</p>

          <div className="flex justify-end gap-3 mt-5">
            <button
              className="text-slate-800  dark:text-slate-50 px-4 py-2 rounded-md border border-solid border-slate-500 text-sm cursor-pointer font-medium"
              onClick={onClose}
            >
              Đóng
            </button>
            <button
              className="px-4 py-2 rounded-md bg-primary text-slate-50 text-sm cursor-pointer font-medium"
              onClick={onConfirm}
            >
              Đồng ý
            </button>
          </div>
        </div>
      </DialogComponent>
    </div>
  );
};

export default DeletePost;

import { useEffect, useState } from 'react';
import { FetchPostInGroupParams } from '../../models';
import { groupService } from '../../services/group.service';

export const usePostGroupHook = ({ pageNumber, groupId }: { pageNumber: number; groupId: string }) => {
  const [posts, setPosts] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(false);

  const appendNewPost = (newPost: any) => {
    console.log('🚀 ~ appendNewPost ~ newPost:', newPost.data);
    const parsedData = JSON.parse(newPost.data);
    setPosts((prev) => [parsedData, ...prev]);
  };
  const removePost = (postId: string) => {
    setPosts(posts.filter((post) => post.id !== postId));
  };
  const getPosts = async (payload: FetchPostInGroupParams) => {
    try {
      setLoading(true);
      const data = await groupService.getPosts(payload);
      setPosts((prev) => [...prev, ...data.data.data]);
      setHasMore(data.data.hasMore);
    } catch {
      //
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    groupId &&
      getPosts({
        pageNumber,
        pageSize: 10,
        groupId,
      });
  }, [pageNumber, groupId]);

  return {
    data: posts,
    loading,
    hasMore,
    appendNewPost,
    removePost,
  };
};

export const routePaths = {
  home: '/',
  profile: '/profile',
  profileUser: '/user/:userId',
  notifications: '/notifications',
  creators: '/creators/:id',
  creatorsRequest: '/account/creator-request',
  friend: 'friend',
  listFriend: 'list-friend',
  gate: '/gate',
  chat: '/chat',
  videos: '/videos',
  videoDetail: '/videos/:id',
  register: '/account/register',
  login: '/account/login',
  verifyEmail: '/account/verify-email',
  userSetting: '/settings',
  notiDonate: '/donate-screen/:contentCreatorId',
  group: '/group',
  groupDetail: '/group/:groupId',
};
